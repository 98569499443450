@import url('/fonts/BaristaBot_Ginto_Nord/BaristaBotGintoNord-Bold.woff2') format('woff2'); /* Modern Browsers */
@import url('/fonts/BaristaBot_Ginto_Nord/BaristaBotGintoNord-Bold.woff') format('woff'); /* Older Browsers */
@import url('/fonts/Px-Grotesk/Px-Grotesk-Bold.woff2') format('woff2'); /* Modern Browsers */
@import url('/fonts/Px-Grotesk/Px-Grotesk-Bold.woff') format('woff'); /* Older Browsers */
@import url('/fonts/Px-Grotesk/Px-Grotesk-Regular.woff2') format('woff2'); /* Modern Browsers */
@import url('/fonts/Px-Grotesk/Px-Grotesk-Regular.woff') format('woff'); /* Older Browsers */
  
  body {
background-color: #E6E5E1;
margin: 0;
}

/* Elements */

/* src/fonts/fonts.css */
@font-face {
  font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
  src: local('BaristaBot Ginto Nord Bold'),
     url('/fonts/BaristaBot_Ginto_Nord/BaristaBotGintoNord-Bold.woff2') format('woff2'), /* Modern Browsers */
     url('/fonts/BaristaBot_Ginto_Nord/BaristaBotGintoNord-Bold.woff') format('woff'); /* Older Browsers */
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'PX Grotesk Bold', sans-serif;
  src: local('PX Grotesk Bold'),
     url('/fonts/Px-Grotesk/Px-Grotesk-Bold.woff2') format('woff2'), /* Modern Browsers */
     url('/fonts/Px-Grotesk/Px-Grotesk-Bold.woff') format('woff'); /* Older Browsers */
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'PX Grotesk Light', sans-serif;
  src: local('PX Grotesk Light'),
     url('/fonts/Px-Grotesk/Px-Grotesk-Light.woff2') format('woff2'), /* Modern Browsers */
     url('/fonts/Px-Grotesk/Px-Grotesk-Light.woff') format('woff'); /* Older Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PX Grotesk Regular', sans-serif;
  src: local('PX Grotesk Regular'),
     url('/fonts/Px-Grotesk/Px-Grotesk-Regular.woff2') format('woff2'), /* Modern Browsers */
     url('/fonts/Px-Grotesk/Px-Grotesk-Regular.woff') format('woff'); /* Older Browsers */
  font-weight: 400;
  font-style: normal;
}





/* Add more @font-face rules for additional font weights or styles as needed */



.App-logo {
  height: 76px; /* Or any other dimensions */
  /* Other styles */
}

.button {
  font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
  background-color: #E6E5E1;
  color: #000000; /* Black text */
  border: 3px solid #000000; /* Grey border */
  padding: 16px 32px; /* Adjust padding to match your button's size */
  font-size: 16px; /* Adjust font-size as needed */
  border-radius: 20px; /* Adjust border-radius for roundness of corners */
  cursor: pointer; /* Changes cursor to pointer on hover */
  text-align: center; /* Ensures text is centered */
  text-decoration: none; /* Removes underline from links if used on an 'a' element */
  display: inline-block; /* Allows setting padding and other box model properties */
  margin: 4px 2px; /* Spacing around the button */
  transition-duration: 0.4s; /* Smooth transition for hover effects */
  font-style: normal;
  font-weight: normal;
  }
  
  .button:hover {
    border: 3px solid #0D82FF; /* Grey border */
  }
  
  .tmrw-button {
  font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
  background-color: #FFF;
  color: #000000; /* Black text */
  border: 3px solid #FF2A00; /* Grey border */
  padding: 16px 32px; /* Adjust padding to match your button's size */
  font-size: 16px; /* Adjust font-size as needed */
  border-radius: 20px; /* Adjust border-radius for roundness of corners */
  cursor: pointer; /* Changes cursor to pointer on hover */
  text-align: center; /* Ensures text is centered */
  text-decoration: none; /* Removes underline from links if used on an 'a' element */
  display: inline-block; /* Allows setting padding and other box model properties */
  margin: 4px 2px; /* Spacing around the button */
  transition-duration: 0.4s; /* Smooth transition for hover effects */
  font-style: normal;
  font-weight: normal;
  }
  
  .tmrw-button:hover {
    border: 3px solid #000 /* Grey border */
  }
  
  .mk-button {
  font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
  background-color: #FFF;
  color: #000000; /* Black text */
  border: 3px solid #000000; /* Grey border */
  padding: 16px 32px; /* Adjust padding to match your button's size */
  font-size: 16px; /* Adjust font-size as needed */
  border-radius: 20px; /* Adjust border-radius for roundness of corners */
  cursor: pointer; /* Changes cursor to pointer on hover */
  text-align: center; /* Ensures text is centered */
  text-decoration: none; /* Removes underline from links if used on an 'a' element */
  display: inline-block; /* Allows setting padding and other box model properties */
  margin: 4px 2px; /* Spacing around the button */
  transition-duration: 0.4s; /* Smooth transition for hover effects */
  font-style: normal;
  font-weight: normal;
  }
  
  .mk-button:hover {
    border: 3px solid #0D82FF; /* Grey border */
  }
  
  .about-button {
    font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
    box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.10);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    background-color: #EAFF00; /* The button color from your design */
    color: black;
    border: none;
    border-radius: 20px;
    padding: 16px 32px;
    margin-right: 24px;
    margin-bottom: 60px;
    cursor: pointer;
    /* Add hover effect if needed */
  }
  
  .char-button:hover {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    background-color: #BBCC00;
  }
  
  .char-button {
    font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
    box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.10);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    background-color: #EAFF00; /* The button color from your design */
    color: black;
    border: none;
    border-radius: 20px;
    padding: 16px 32px;
    margin-right: 24px;
    margin-bottom: 60px;
    cursor: pointer;
    /* Add hover effect if needed */
  }
  
  .char-button:hover {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    background-color: #BBCC00;
  }
  
  
  .char-button-stacked {
    font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
    box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.10);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    background-color: #EAFF00; /* The button color from your design */
    color: black;
    border: none;
    border-radius: 20px;
    padding: 16px 32px;
    margin-right: 24px;
    margin-bottom: 20px;
    cursor: pointer;
    /* Add hover effect if needed */
  }
  
 .char-button-stacked:hover {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    background-color: #BBCC00;
  }
  
  .char-button-medium {
    font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
    box-shadow: 2px 2px 0px 0px rgba(0, 0, 0, 0.10);
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    background-color: #EAFF00; /* The button color from your design */
    color: black;
    border: none;
    border-radius: 16px;
    padding: 12px 24px;
    margin-right: 24px;
    margin-bottom: 60px;
    cursor: pointer;
    /* Add hover effect if needed */
  }
  
  .char-button-medium:hover {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    background-color: #BBCC00
  }

.MailChimp {
  max-width: 500px;
  padding-top: 64px;
  align-items: center;
}



.submit-button {
    font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
    box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.10);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    background-color: #000; /* The button color from your design */
    color: white;
    border: none;
    text-decoration: none;
    border-radius: 20px;
    padding: 16px 32px;
    margin-right: 24px;
    margin-bottom: 60px;
    cursor: pointer;
    text-align: center;
    /* Add hover effect if needed */
  }
  
  .submit-button:hover {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    background-color: #2E2E2D;
  }
  
  .cancel-button {
    font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
    border-radius: 20px;
    background: var(--White, #FFF);
    box-shadow: 4px 4px 0px 0px #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    color: black;
    border: none;
    text-decoration: none;
    padding: 16px 32px;
    margin-right: 24px;
    margin-bottom: 60px;
    cursor: pointer;
    text-align: center;
    /* Add hover effect if needed */
  }
  
  .cancel-button:hover {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    background-color: #fdfdfd;
  }
  
  .close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    border-radius: 12px;
    background: var(--White, #FFF);
    box-shadow: 2px 2px 0px 0px #000;
    padding: 6px;
    cursor: pointer;
    border: none;
  }
  
  .close-button:hover {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    background-color: #fdfdfd;
  }
  
  .close-button img {
  width: 36px;
  height: 36px;
  }

  
  .social-button {
    font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
    box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.10);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    text-decoration: none; /* Removes underline from links */
    display: inline-flex; /* Aligns text within the button */
    align-items: center;
    background-color: #EAFF00; /* The button color from your design */
    color: black;
    border: none;
    border-radius: 20px;
    padding: 16px 32px;
    margin-right: 24px;
    margin-bottom: 60px;
    cursor: pointer;
    /* Add hover effect if needed */
  }
  
  .social-button:hover {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    background-color: #BBCC00
  } 
  
  .sf-social-button {
  font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  text-decoration: none; /* Removes underline from links */
  display: inline-flex; /* Aligns text within the button */
  align-items: center;
  background-color: #E6E5E1; /* The button color from your design */
  color: black;
  border: 3px solid var(--Black, #000);
  border-radius: 20px;
  padding: 16px 32px;
  margin-right: 24px;
  margin-bottom: 60px;
  cursor: pointer;
  }
  
  .sf-social-button:hover {
    border: 3px solid #0D82FF; /* Blue border */
  } 

  
  h1 {
    font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 24px; 
  }
  
  
  h2, {
    font-family: 'PX Grotesk Regular', sans-serif;
  }
  
  h3 {
    color: var(--White, #FFF);
    font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  
  h4 {
    font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 24px; 
  }
  
  
  p {
    font-family: 'PX Grotesk Regular', sans-serif;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0px; 
  }
  
  
  
 /* Header & Navigation Section */ 
 
header {
  
   padding: 20px;
   display: flex;
   justify-content: space-between;
   align-items: center;
 }
 

.App-header {
  position: relative; /* This is essential */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
}

.App-logo-container {
  flex: 1;
}

/* Hamburger icon */
.hamburger {
  display: none; /* Hidden by default */
  flex-direction: column;
  gap: 7px;
  cursor: pointer;
  border-radius: 12px;
  background: var(--White, #FFF);
  box-shadow: 2px 4px 12px rgba(0,0,0,0.1);
  padding: 16px;
}

.hamburger:hover {
  box-shadow: 0 0 0 0 ;
}

.hamburger div {
  width: 34px;
  height: 5px;
  background-color: black;
  border-radius: 10px;
}

.hamburger.active div:nth-child(1) {
  transform: rotate(45deg) translate(9.5px, 8px);
}

.hamburger.active div:nth-child(2) {
  opacity: 0;
}

.hamburger.active div:nth-child(3) {
  transform: rotate(-45deg) translate(9.5px, -7.5px);
}

.App-nav {
  flex: 2; /* Gives the navigation twice the space to allow centering */
  display: flex;
  justify-content: center;
  list-style: none; /* Removes default list styling */
  padding: 0; /* Resets padding */
}

.App-nav.show {
  display: block; /* Adjust based on your needs */
}

.App-nav a {
  text-decoration: none;
  cursor: pointer;
  color: black; /* Or any other color */
  margin-left: 32px; /* This adds spacing between the links */
  /* Add other styles such as padding, font-size, etc., as needed */
}



.App-signin-container {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.nav-font {
  font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
  font-size: 16px;
  padding: 14px 24px;
}

/* Additional styles for hover effects */
.App-nav a:hover {
  text-decoration: underline;
}

/* Default Section */

.default-section {
  background: #FFF;
  border-radius: 72px; /* This value determines how rounded the corners will be */
  font-color: #000000;
  padding: 72px 128px 72px 128px;
  text-align: center;
  margin: 24px;
}
.default-section img {
  width: 100%;
  padding-bottom: 32px;
}

.default-content {
padding-left: 128px;
padding-right: 128px;
}

.default-content H1 {
  padding-bottom: 48px;
}

.default-body {
  font-family: 'PX Grotesk Regular', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
}

.about-section {
background: #FFF;
border-radius: 72px; /* This value determines how rounded the corners will be */
padding: 72px 128px 72px 128px;
margin: 24px;
align-items: center;
text-align: center;
}

.about-content {
padding-left: 168px;
padding-right: 168px;

font-color: #000000;
}

.about-content p {
  font-family: 'PX Grotesk Regular', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
 
}




/* Slides Section */
.slides-section {
  width: 100%;
  overflow: hidden; /* Ensures nothing overflows the section boundaries */
}

.slides-content {
  padding: 10px;
  text-align: center;
  margin-top: 64px;
}

.slides {
 box-shadow: 0 5px 10px rgba(0,0,0,0.1);
 border-radius: 8px;
 display: block;
 margin-bottom: 1rem;
 padding-bottom: 0px !important;
}




/* Hero Section */

.hero {
  background: #E6E5E1;
  font-color: #000000;
  padding: 120px 128px;
  text-align: center;
}

.hero H1 {
  padding-left: 48px;
  padding-right: 48px;
}

.hero p {
  padding-left: 48px;
  padding-right: 48px;
}


/* Features Section */

.features-section {
  background-color: #000000; /* Dark black background */
  color: #EAFF00; /* White text */
  border-radius: 72px; /* This value determines how rounded the corners will be */
  padding: 60px 128px; /* Add padding to ensure the content doesn't touch the edges */
  margin: 24px;
}

.feature-image-container {
  /* Control the image container size and alignment */
  display: flex;
  align-items: flex-end; /* This will align the image to the bottom */
}

.feature-image {
  /* Adjust max-width as needed to control the image size */
  max-width: 71px;
  height: auto; /* Keep the image aspect ratio */
  padding-bottom: 30px;
}

.QR-image {
  /* Adjust max-width as needed to control the image size */
  width: 100%;
  max-width: 600px;
  height: auto; /* Keep the image aspect ratio */
  margin-bottom: 20px;
  border-radius: 32px;
}


.features h1 {
  
}

.features-header {
  color: #EAFF00;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.features-body {
  margin-top: 120px;
  margin-bottom: 60px;
  width: 100%;
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap to the next line if not enough space */
  justify-content: space-around; /* Space items evenly */
  align-items: flex-start; /* Align items at the start of the cross axis */
  gap: 48px;
}


.features-body p {
  color: var(--Gray, #E6E5E1);
  font-size: 16px; 
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 131.25% */
  text-align: left;
  
}

.features-about {
  margin-top: 120px;
  margin-bottom: 60px;
  width: 100%;
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap to the next line if not enough space */
  justify-content: space-around; /* Space items evenly */
  align-items: flex-start; /* Align items at the start of the cross axis */
  gap: 48px;
}

.features-about p {
  color:  var(--Black, #000);
  font-size: 16px; 
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 131.25% */
  text-align: left;
  
}

.feature-item1, .feature-item2, .feature-item3, .feature-item4 {
  flex-grow: 1; /* Allows items to grow and fill the available space */
  flex-basis: 0; /* Starts from 0 and grows as much as it can */
  padding: 64px;
  border-radius: 72px;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
}

.feature-item1 {
  border: 3px solid #EAFF00; /* Chartreuse border */
}

.feature-item2 {
  border: 3px solid #00BD7D; /* Green border */
}

.feature-item3 {
  border: 3px solid #FF2A00;
  color: #000;
}

.feature-item4 {
  border: 3px solid #000; /* Green border */
  color: #000
}



.header-feature-image, .body-feature-image {
  max-width: 100%;
  height: auto;
  margin-top: 20px; /* Space above image */
  
}

.feature-item1 h4 {
color: var(--Green, #EAFF00);
font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-transform: uppercase;
margin-bottom: 0.5em; /* Space below the h3 */
border-radius: 16px;
border: 4px solid var(--Chartreuse, #EAFF00);
padding: 8px;
justify-content: center;
}

.feature-item1 h3 {
 color: var(--Green, #EAFF00);
 font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
 font-size: 32px;
 font-style: normal;
 font-weight: 700;
 line-height: normal;
 margin-bottom: 1em; /* Space below the paragraph */

}

.feature-item2 h4 {
  color: var(--Green, #00BD7D);
  font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 0.5em; /* Space below the h3 */
  border-radius: 16px;
  border: 4px solid var(--Green, #00BD7D);
  padding: 8px;
  justify-content: center;
}

.feature-item2 h3 {
  color: var(--Green, #00BD7D);
  font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 1em; /* Space below the paragraph */
  
}

.feature-item3 h4 {
color: #FF2A00;
font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-transform: uppercase;
margin-bottom: 0.5em; /* Space below the h3 */
border-radius: 16px;
border: 4px solid #FF2A00;
padding: 8px;
justify-content: center;
}

.feature-item3 h3 {
 color: #FF2A00;
 font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
 font-size: 32px;
 font-style: normal;
 font-weight: 700;
 line-height: normal;
 margin-bottom: 1em; /* Space below the paragraph */

}

.feature-item4 h4 {
  color:  var(--Black, #000);
  font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 0.5em; /* Space below the h3 */
  border-radius: 16px;
  border: 4px solid var(--Black, #000);
  padding: 8px;
  justify-content: center;
}

.feature-item4 h3 {
  color: var(--Black, #000);
  font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 1em; /* Space below the paragraph */
  
}

/* Funding Section */

.funding {
  background-color: #0D82FF; /* Dark green background */
  color: #FFF; /* White text */
  border-radius: 72px; 
  padding: 60px 128px; 
  margin: 24px; 
  
}

.funding p {
  color: var(--White, #FFF);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 150% */
  text-align: left;
  margin-bottom: 24px;
}
  .funding-image1 {
    height: 60px;
    margin: 24px;
    padding: 12px 0 12px 0;
  }
  
  .funding-image2 {
    height: 36px;
    margin: 24px;
    padding: 24px 0 24px 0;
  }
  
  .funding-image3 {
    height: 60px;
    margin: 24px;
    padding: 12px 0 12px 0;
  }
  
  .funding-image4 {
    height: 70px;
    margin: 24px;
    padding: 0 0 12px 0;
  }
  
  .partner-boxes {
    display: flex;
    justify-content: space-between;
    margin-bottom: 96px;
    margin-top: 20px;
  }
  
  .partner-box1 {
    background-color: #FFFF; /* Adjust color as needed */
    border: 6px solid var(--Green, #00BD7D);
    border-radius: 44px;
    padding: 20px;
    width: 20%; /* Adjust width based on your design */
    text-align: left;
  }
  
  .partner-box1 span {
    color: #00A06A;
    font-family: 'PX Grotesk Bold', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin: 24px;
  }
  
  .partner-box1 h3 {
    font-size: 1.2rem;
    margin: 0.5rem 0;
  }
  
  .partner-box1 p {
    font-size: 0.9rem;
    color: #1E1E1E;
    font-family: 'PX Grotesk Bold', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  
  .partner-box2 {
    background-color: #042B56; /* Adjust color as needed */
    border-radius: 44px;
    padding: 20px;
    width: 20%; /* Adjust width based on your design */
    text-align: left;
  }
  
  .partner-box2 span {
    color: #00A06A;
    font-family: 'PX Grotesk Bold', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin: 24px;
  }
  
  .partner-box2 h3 {
    font-size: 1.2rem;
    margin: 0.5rem 0;
  }
  
  .partner-box2 p {
   color: var(--White, #FFF);
   font-family: 'PX Grotesk Bold', sans-serif;
   font-size: 20px;
   font-style: normal;
   font-weight: 700;
   line-height: normal;
  }
  
  .funding-contact-container {
    display: flex;
    align-items: center;
    justify-content: flex-startstart;
    margin-top: 40px; /* Adjust as needed */
  }
  
  .funding-contact-container p {
    color: var(--White, #FFF);
    font-family: 'PX Grotesk Regular', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 140% */
  }
  
  
  .funding-image-container {
   flex: 0 1 auto; /* Adjust as needed, or use flex-basis with a fixed width */
    padding-right: 12px; /* Adjust as needed */
  }
  
  .funding-image {
    max-width: 120px;
    height: auto; /* Maintain aspect ratio */
    /* Add any additional styling for the image */
  }
  
  .funding-info-container {
    flex: 2 1 auto; /* Adjust as needed, or use flex-basis with a fixed width */
    padding-left: 12px; /* Adjust as needed */
    max-width: 540px;
    text-align: left; /* Align text to the left */
    margin-top: 10px;
  }
  
  .funding-button-container {
    flex: 1 1 auto; /* Adjust as needed, or use flex-basis with a fixed width */
    padding-left: 36px; /* Adjust as needed */
    text-align: left; /* Align text to the left */
    margin-top: 40px;
  }
  
  /* Team Section */
  
  
  .bento {
    width: 100%; /* Full width of its container to start */
    max-width: 1600px; /* Maximum width to ensure the image is not too large */
    padding: 20px 0; /* Adjust as needed */
  }
  
  .bento-image {
    width: 100%; /* Make the image responsive to the width of its container */
    height: auto; /* Maintain the aspect ratio of the image */
    border-radius: 28px; /* Optional: rounded corners for the image */
  }
  
  
  .team {
    background-color: #0D82FF; /* Dark green background */
    color: #FFF; /* White text */
    border-radius: 72px; 
    padding: 60px 128px; 
    margin: 24px; 
    
  }
  
  .team p {
    color: var(--White, #FFF);
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; /* 150% */
    text-align: left;
    margin-bottom: 24px;
  }

    
    .team-contact-container {
      display: flex;
      align-items: center;
      justify-content: flex-startstart;
    }
    
    .team-contact-container p {
      color: var(--White, #FFF);
      font-family: 'PX Grotesk Regular', sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px; /* 140% */
    }
    
    
    
    .team-info-container {
      flex: 2 1 auto; /* Adjust as needed, or use flex-basis with a fixed width */
      padding-left: 12px; /* Adjust as needed */
      max-width: 800px;
      text-align: left; /* Align text to the left */

    }
    
    .team-button-container {
      flex: 1 1 auto; /* Adjust as needed, or use flex-basis with a fixed width */
      padding-left: 36px; /* Adjust as needed */
      text-align: left; /* Align text to the left */
      margin-top: 40px;
    }



/* Contact Section*/
.contact-section {
  display: flex;
  justify-content: space-between;
  padding: 60px 128px; 
  background-color: #EAFF00; /* Replace with the correct color */
  border-radius: 72px; /* Adjust as needed */
  margin: 24px;
  text-align: left; 

}



.contact-content {
  flex: 2; /* Takes up 2/3rds of the space */
  padding-right: 20px; /* Ensures space between the text and the form */
  align-items: flex-start;
  
}

.contact-form {
  flex: 1; /* Takes up the other third */
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligns form items to the start (left) */
  margin-top: 40px;
}

.contact-form input[type="tel"] {
  margin-bottom: 10px; /* Space between input and small text */
  border-radius: 24px;
  border: 4px solid #000;
  display: flex;
  padding: 16px;
  gap: 10px;
  overflow: hidden;
  color: var(--Black, #000);
  text-overflow: ellipsis;
  font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  max-width: 300px;
  background: var(--White, #FFF);
  /* Style your input field */
}

.contact-form .small-text {
  font-size: 10px;
  padding-left: 20px;
  margin-bottom: 16px; /* Space between small text and button */
  width: 100%;
  max-width: 300px;
}

.contact-form .submit-button {
  /* Style your sign up button */
}

  
.contact-section p {
  font-color: #000; /* White text */
  text-align: left;
  width: 80%;
  font-size: 24px;
}

.contact-section H1 {
  color: var(--Black, #000);
  font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}



/* Footer Section */

.footer {
  text-align: left;
  padding: 120px 128px;
  background-color: #E6E5E1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}


.footer-copy {
  text-align: left;
}

.footer-copy a {
  text-decoration: none;
  color: #333;
  cursor: pointer;
}
  
  .footer-copy p {
  color: #333;
  font-family: 'PX Grotesk Regular', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -0.176px;
  text-align: left;
}

.footer-content {
  display: flex; /* Aligns logo and bubble side by side */
  align-items: center; /* Aligns items vertically in the center */
}

.footer-bot img {

  margin-right: 10px;
  width: 100%;
  max-width: 400px; /* Space between bot and text bubble if needed */
}

.footer-baristabot {
  margin: 64px 0 64px 0 ; /* Space above and below the button area */
}

.social-media-buttons {
  display: flex;
  cursor: pointer;
  justify-content: flex-start; /* Aligns buttons to the left */
  gap: 20px; /* Space between buttons */
  
}

/* No Footer Section */


.no-footer {
  background-color: #E6E5E1; /* Adjust with your actual color */
  padding: 120px 128px;
  align-content: center;
}

.no-footer-copy {
  text-align: center;
}

.no-footer-copy a {
  text-decoration: none;
  color: #333;
  cursor: pointer;
}

  .no-footer-copy p {
  color: #333;
  font-family: 'PX Grotesk Regular', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -0.176px;
}


/* Small Footer Section */


.small-footer {
  text-align: left; /* Aligns content to the left */
  background-color: #E6E5E1; /* Adjust with your actual color */
  display: flex; /* Establishes a flex container */
  flex-direction: column; /* Stacks children vertically */
  align-items: flex-start; /* Aligns children to the start of the cross-axis (left) */
  padding: 120px 128px;
}

.small-footer-content {
  display: flex; /* Aligns logo and bubble side by side */
  align-items: center; /* Aligns items vertically in the center */
}

.small-footer-bot img {
  width: 100%; /* Adjust the width of the bot image */
  max-width: 220px; 

}

.small-footer-baristabot {
  margin-top: 24px;
  margin-bottom: 24px;
  width: 300px;
}


.sf-social-media-buttons {
  display: flex;
  justify-content: flex-start; /* Aligns buttons to the left */
  gap: 20px; /* Space between buttons */
  margin-top: 24px;
  margin-bottom: 24px;
  
}

.small-footer-copy {
  text-align: left;
}
  
.small-footer-copy a {
  text-decoration: none;
  color: #333;
  cursor: pointer;
}

  .small-footer-copy p {
  color: #333;
  font-family: 'PX Grotesk Regular', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -0.176px;
  text-align: left;
}


  /* NOT FOUND */


.not-found-container {
  display: flex;
  padding: 72px 128px 0px 128px;
  flex-direction: column;
  align-items: center;
  gap: 72px;
  align-self: stretch;
 
  /* Add additional styling as needed */
}

.chat-bubbles-404 {
  align-items: center;
  min-height: 540px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  width: 426px;
  padding: 24px; /* Add padding inside the chat container */
  position: relative;
  border-radius: 48px;
  background: var(--White, #FFF);
  box-shadow: 0px 32px 48px 0px rgba(0, 0, 0, 0.12), 0px 24px 24px -12px rgba(0, 0, 0, 0.05), 0px 12px 12px -6px rgba(0, 0, 0, 0.05), 0px 6px 6px -3px rgba(0, 0, 0, 0.05), 0px 3px 3px -1.5px rgba(0, 0, 0, 0.05), 0px 1px 1px -0.5px rgba(0, 0, 0, 0.05), 0px 0px 0px 1px rgba(0, 0, 0, 0.05);
  
  /* Add additional styling as needed */
}

.chat-bubbles {
  align-items: center;
  min-height: 600px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  width: 426px;
  padding: 24px; /* Add padding inside the chat container */
  position: relative;
  border-radius: 48px;
  background: var(--White, #FFF);
  box-shadow: 0px 32px 48px 0px rgba(0, 0, 0, 0.12), 0px 24px 24px -12px rgba(0, 0, 0, 0.05), 0px 12px 12px -6px rgba(0, 0, 0, 0.05), 0px 6px 6px -3px rgba(0, 0, 0, 0.05), 0px 3px 3px -1.5px rgba(0, 0, 0, 0.05), 0px 1px 1px -0.5px rgba(0, 0, 0, 0.05), 0px 0px 0px 1px rgba(0, 0, 0, 0.05);
  
  /* Add additional styling as needed */
}


.bubble_r, .bubble_l, .go-home-link  {
  opacity: 0;
  border-radius: 24px;
  padding: 16px; /* Adjust padding as needed */
  margin-bottom: 16px; /* This adds a gap between bubbles */
  display: inline-flex; /* This will shrink the bubble to fit the content */
  max-width: 65%; /* Prevents bubbles from being too wide */
  
  
}

.bubble_r {
  background: var(--Blue, #0D82FF);
  color: var(--Neon-Yellow, #EAFF00);
  margin-left: auto; /* Pushes the bubble to the right */
  align-self: flex-end; /* Aligns the bubble to the right */
  font-family: 'PX Grotesk Regular', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.4; /* Adjusts the line height to ensure the bubble height fits the text */
  text-align: left;
}

.bubble_l {
  background: var(--Gray, #E6E5E1);
  align-self: flex-start; /* Aligns the bubble to the left */
  font-family: 'PX Grotesk Regular', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.4; /* Adjusts the line height to ensure the bubble height fits the text */
  text-align: left;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

#bubble1 {
  animation: fadeInUp 0.5s ease forwards 0.5s;
}

#bubble2 {
  animation: fadeInUp 0.5s ease forwards 1.5s;
}

#bubble3 {
  animation: fadeInUp 0.5s ease forwards 2.5s;
}

#bubble4 {
  animation: fadeInUp 0.5s ease forwards 3.5s;
}

#bubble5 {
  animation: fadeInUp 0.5s ease forwards 4.5s;
}
#bubble6 {
  animation: fadeInUp 0.5s ease forwards 5.5s;
}

/* Add this to your NotFound.css */

.go-home-link {
  background: var(--Black, #000000);
  color: var(--Neon-Yellow, #EAFF00);
  font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  cursor: pointer; /* Change mouse cursor on hover */
  align-self: flex-start; /* Aligns the bubble to the left */
}

.go-home-link a {
  text-decoration: none; /* Removes the underline from the link */
  color: var(--Black, #EAFF00);
}

.go-home-link:hover {
  background-color: #EAFF00; /* Slightly lighter color on hover */
  color: var(--Black, #000000);
}

.go-home-link:hover a {
  color: var(--Black, #000000);
}


