@media (max-width: 1375px) {
	
	
	  .partner-boxes {
			flex-direction: column;
			align-items: center;
		  }
		
		.partner-box1, .partner-box2 {
			width: 75%; /* Adjust based on your preference */
			margin-bottom: 32px; /* Add some space between the boxes when they stack */
		  }


	}






@media (max-width: 1190px) {
	  
	  
	  
	  .footer-title {
		font-size: 400px; /* Adjust for smaller screens */
	  }
	
.App-nav {
	display: none; /* Hide regular nav */
	
  }

.App-header.nav-open .nav-font {
	padding: 0;
}

	/* Show hamburger icon */
  .hamburger {
	display: flex;
	order: 2; /* Ensure it's positioned at the end */
  }
  
  .App-signin-container {
	  display: none; /* Hide the sign-up button */
	}
  
	.App-header.nav-open .App-nav {
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 80%;
	right: 0;
	align-items: right;
	height: 220px;
	margin-right: 20px;
	z-index: 100; /* Ensures the menu is above other content */
	overflow-y: auto; /* Allows scrolling within the menu */		width: 200px;
	background-color: #EAFF00;
	border-radius: 24px;
	}
	
	.App-header.nav-open .App-nav a {
	  display: block; /* Makes each link full-width */
	  padding: 10px 20px; /* Adds space around the text for easier tap targets */
	
	  color: #333; /* Text color */
	  text-align: right; /* Centers the link text */
	}  
	
	.default-section, .slides-content, .default-content, .about-content {
		padding: 12px 24px; /* Reduce padding for smaller screens */
	  }
	  
	  .partner-boxes {
		  flex-direction: column;
		  align-items: center;
		}
	  
	  .partner-box1, .partner-box2 {
		  width: 75%; /* Adjust based on your preference */
		  margin-bottom: 32px; /* Add some space between the boxes when they stack */
		}
		
		.contact-section {
		  flex-direction: column;
		}
		.contact-content p {
			margin-bottom: 24px;
		}
		.contact-content h1 {
		   font-size: 56px;
		   font-style: normal;
		   font-weight: 700;
		   margin-bottom: 24px; 
		}
		
		.contact-form {
		  width: 100%; /* Ensure the contact form uses full width */
		  max-width: 640px;
		  margin-top: 24px;
		}
		
		.contact-form input[type="tel"] {
			width: 100%;
			max-width: 340px;
		}
		
	.contact-form .small-text {
		font-size: 10px;
		padding-left: 20px;
		margin-bottom: 16px;
		width: 100%;
		max-width: 340px;
	}

	  
	  .footer {
		  padding: 64px 64px; /* Reduce padding */
		}
		
		.small-footer {
			padding-left: 32px 32px; /* Adjust padding as needed */
		  }
		
		.footer-baristabot {
			width: 100%; /* Scale down to 50% of its container width */
			max-width: 620px; /* Optional: ensure it doesn't grow larger than its original size */
			margin-top: 32px;
			
		  }
		  
		  .small-footer-baristabot {
			  width: 100%; /* Scale down to 50% of its container width */
			  max-width: 420px; /* Optional: ensure it doesn't grow larger than its original size */
			  margin-top: 32px;
			  padding-left: 32px; /* Adjust padding as needed */
			}
		 
		  .footer-bot img {
			width: 100%; /* Adjust the width of the bot image */
			max-width: 420px; 
		  }

		  .small-footer-bot img {
			width: 100%; /* Adjust the width of the bot image */
			max-width: 220px; 
		  
		  }
		
		  .social-media-buttons {
			gap: 10px; /* Adjust the gap between buttons */
			margin-bottom: 32px
		  }
		  
		  .sf-social-media-buttons {
			gap: 10px; /* Adjust the gap between buttons */
			padding-left: 32px; 
		  }
		  
		  .footer-copy p {
				padding-left: 32px;
			}
			
			.small-footer-copy p {
			  padding-left: 32px;
		  }
		  

		  .emoji-gallery {
			text-align: center;
		  }
		  
		  .emoji-selector {
			margin-bottom: 20px; /* Space below emoji selector */
		  }
		  
		  .emoji {
			color: var(--Black, #000);
			text-align: center;
			font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
			font-size: 54px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			padding: 12px 24px;
			gap: 10px;
			border-radius: 18px;
			border: 2px solid var(--Gray, #E6E5E1);
			background: #FFF;
			gap: 16px;
		  }
		  
		  .emoji.active {
			  gap: 10px;
			  border-radius: 18px;
			  border: 4px solid var(--Blue, #0D82FF);
			  background: #FFF;
		  }
		  
		  
		  .image-container {
			/* Adjust this container to fit the design */
			margin: auto;
			margin-top: 64px;
			width: 100%;
			max-width: 426px;
			padding: 0;
		  }
		  
		  .conversation-container {
			  /* Adjust this container to fit the design */
			  margin: auto;
			  margin-top: 64px;
			  width: 100%;
			  max-width: 426px;
			  padding: 0;
			}
		  
		  .main-image {
			max-width: 100%;
			height: auto; /* Keeps the aspect ratio of the image */
			/* Further styling as needed */
		  }
		  
		 
			
			h1 {
				font-size: 54px;
				margin-bottom: 24px; 
			  }
			  
			  p {
				  font-size: 26px;
				  margin-bottom: 64px; 
				}
		  
		  
	  }


		




@media (max-width: 768px) { /* Adjust breakpoint as needed */
  .main-image {
	  width: 100%;
		padding: 0;
		margin: 0; 
  }
  .image-container {
	  /* Adjust this container to fit the design */
	  margin: 0;
	  margin-top: 24px;
	  width: 100%;
	  max-width: 500px;
	  padding: 0;
	}
	.conversation-container {
		padding: 0;
		margin: 0;
	}
   
   .emoji-gallery {
	 text-align: center;
	 padding: 0;
	 margin-top: 24px;
   }
   
   .modal-content h3 {
		 font-size: 24px !important;
	 }
	 
	 .modal-content {
	   margin-top: 80px;
	   width: 80%;
	   height: fit-content;
	   padding: 10px;
	 }
	 
   .emoji-selector {
		margin-bottom: 20px;
		padding-top: 0; /* Space below emoji selector */
		gap: 10px;
	  }
	  
   .emoji {
	   border-radius: 18px;
	   padding: 10px 16px;
	   font-size: 36px;
   }
   
   .emoji-selector {
	 margin-bottom: 20px;
	 padding-top: 0; /* Space below emoji selector */
	 gap: 10px;
   }
   
   .bubble_r, .bubble_l {
	 opacity: 0;
	 text-decoration: none !important;
	 font-size: 16px;
	 line-height: 1.2; /* Adjusts the line height to ensure the bubble height fits the text */
	 border-radius: 18px;
	 padding: 12px;
	 margin-bottom: 8px;
   }
   
   .go-home-link {
	   font-size: 14px;
	   text-decoration: none !important;
	   border-radius: 18px;
	   padding: 14px;
	   margin-bottom: 8px;
   }
   .not-found-container {
	   margin: 0;
	   padding: 0;
   }
   
   .not-found-container H1 {
		  margin: 0;
		  padding: 0;
	  }
	  
	  .not-found-container p {
		  padding-right: 12px;
		  padding-left: 12px;
	  }
  
   .chat-bubbles-404 {
	 min-height: 400px;
	 width: 80%;

   }
   
   .chat-bubbles {
		min-height: 540px;
		width: 90%;
  		margin: 24px 0 0;
		 border-radius: 28px;
	  }


  .hero {
		margin: 0;
		padding: 0;
	}
	
	.hero H1 {
		padding-left: 12px;
		padding-right: 12px;
	}
	
	.hero p {
		padding-right: 12px;
		padding-left: 12px;
	}
  
  .default-section, .about-section, .features-section {
	  margin: 0;
	  border-radius: 64px;
  }
  
  .about-content p {
	font-size: 16px;
	line-height: 24px; /* 150% */
  }
  

  .feature-item1, .feature-item2 {
	flex-basis: 100%; /* Each item takes full width on smaller screens */
	padding: 32px; /* Optionally adjust padding for smaller screens */
	margin: 10px 0; /* Adjust margin as needed */
  }
  
  .features-header {
	  flex-direction: column; /* Stack items vertically on smaller screens */
	  padding: 30px 20px; /* Adjust padding for smaller screens */
	}
	
	.par {
		flex-direction: column; /* Stack image and info on top of each other on smaller screens */
		  
	}
	
	.funding p {
	  color: var(--White, #FFF);
	  font-family: 'PX Grotesk Regular', sans-serif;
	  font-size: 16px;
	  font-style: normal;
	  font-weight: 400;
	  line-height: 24px; /* 150% */
	  text-align: left;
	  margin-bottom: 20px;
	}
	
	.funding {
		padding: 30px 32px; /* Adjust padding for smaller screens */
		margin: 0;
		margin-top: 24px;
		border-radius: 64px;
	}
	
	.funding-contact-container {
		flex-direction: column; /* Stack image and info on top of each other on smaller screens */
	  }
	
	  .funding-image-container, .funding-info-container {
		flex-basis: 100%; /* Full width on smaller screens */
		padding: 10px 0; /* Adjust as needed */
	  }
	 
	  
	.partner-box1, .partner-box2 {
		width: 80%; /* Boxes take up full container width */
	  }
		

	  
	.team {
		  padding: 30px 32px; /* Adjust padding for smaller screens */
		  margin: 0;
		  margin-top: 24px;
		  border-radius: 64px;
		 
	  }
	  
	  .team p {
		  color: var(--White, #FFF);
		  font-family: 'PX Grotesk Regular', sans-serif;
		  font-size: 16px;
		  font-style: normal;
		  font-weight: 400;
		  line-height: 24px; /* 150% */
		  text-align: left;
		  margin-bottom: 12px;
		}
		
		.team H3 {
			color: var(--White, #FFF);
			font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
			font-size: 24px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
		}
	  
	  .team-contact-container {
		  flex-direction: column; /* Stack image and info on top of each other on smaller screens */
		}
	  
		.team-image-container, .team-info-container {
		  flex-basis: 100%; /* Full width on smaller screens */
		  padding: 20px 0; /* Adjust as needed */
		  
		}

		.bento {
			margin: 16px auto; /* Less margin on smaller screens */
		  }
		
		  .team-button-container {
			justify-content: center; 
			padding-left: 0;
		  }


		.hero, .features-section, .default-section, .about-section, .contact-section, .team{
			padding: 30px 24px; /* Further reduce padding for smaller devices */
		  }
		  
		
		  .feature-item1, .feature-item2 {
			flex-basis: 100%; /* Make feature items stack vertically */
		  }
		
		  .contact-section {
			flex-direction: column;
			margin: 0;
			margin-top: 24px;
			border-radius: 64px;
		  }
		  .contact-content p {
			  margin-bottom: 24px;
			  width: 100%;
				font-size: 16px;
		  }
		  .contact-content h1 {
			font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
			 font-size: 32px;
			 font-style: normal;
			 font-weight: 700;
			 margin-bottom: 24px; 
		  }
		  
		  .contact-form {
			width: 100%; /* Ensure the contact form uses full width */
			max-width: 320px;
			margin-top: 24px;
		  }
		  
		  .contact-form input[type="tel"] {
			  width: 100%;
			  max-width: 280px;
		  }
		  
			  
		  .contact-form .small-text {
			  font-size: 10px;
			  padding-left: 20px;
			  margin-bottom: 16px;
			  width: 100%;
			  max-width: 290px;
		  }
		  
		  h1 {
			  font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
			  font-size: 32px;
			  font-style: normal;
			  font-weight: 700;
			  margin-bottom: 24px; 
			}
			
			p {
				font-family: 'PX Grotesk Regular', sans-serif;
				text-align: center;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				margin-bottom: 32px; 
			  }
			  
				  
				  .footer {
					padding: 64px 12px; /* Reduce padding */
				  }
				  
				  .small-footer {
					padding: 64px 12px; /* Reduce padding */
				  }
				  
				  .no-footer {
					  padding: 64px 12px; /* Reduce padding */
					}
					
				  .footer-baristabot {
					  width: 100%; /* Scale down to 50% of its container width */
					  max-width: 300px; /* Optional: ensure it doesn't grow larger than its original size */
					  margin-top: 32px;
					  margin-bottom: 32px;
					  padding: 0;
					}
					
					.small-footer-baristabot {
					  width: 100%; /* Scale down to 50% of its container width */
					  max-width: 300px; /* Optional: ensure it doesn't grow larger than its original size */
					  margin-top: 32px;
					  margin-bottom: 32px;
					  padding: 0;
					}
				   
					.footer-bot img {
					  width: 100%; /* Adjust the width of the bot image */
					  max-width: 220px; 
					  padding: 0;
					}
					
					.small-footer-bot img {
						width: 100%; /* Adjust the width of the bot image */
						max-width: 220px; 
						padding: 0;
					  }
					
					.footer-copy p {
						  padding-left: 0;
						  font-size: 12px;
						  padding: 0;
					  }
					  
					.small-footer-copy p {
					  padding-left: 0;
					  font-size: 12px;
					  padding: 0;
					  
						}
						
						.no-footer-copy p {
						  padding-left: 0;
						  font-size: 12px;
						  padding: 0;
						  
							}

					.social-media-buttons {
					 flex-direction: column; /* Stack image and info on top of each other on smaller screens */
					  gap: 10px; /* Adjust the gap between buttons */
					  padding: 0;
					}
					
					.sf-social-media-buttons {
						flex-direction: column; /* Stack image and info on top of each other on smaller screens */
						gap: 10px; /* Adjust the gap between buttons */
						padding: 0;
					  }
					  
					  .mk-button, .char-button-stacked, .char-button, .social-button, .sf-social-button, .submit-button {
						  padding: 14px 28px; /* Adjust padding for smaller buttons */
							  font-size: 14px;
							  margin-bottom: 20px;/* Adjust font size for smaller text */
						
					  }
					  
					  .mk-button,.sf-social-button {
							padding: 12px 24px; /* Adjust padding for smaller buttons */
								font-size: 14px;
								margin-bottom: 10px;/* Adjust font size for smaller text */
						  
						}
					
				.pair {
					flex-direction: column; /* Stack the inputs vertically on smaller screens */
				  }
				  
				  .modal-content h3 {
					  font-size: 24px;
				  }
}
	  
		  

		
	

