
.signup-container {
  background: var(--Gray, #A5A4A3);
}

.onboarding-header {
  background: var(--Gray, #A5A4A3);

}

.onboarding-section{
  background: var(--Gray, #A5A4A3);

}

.onboarding-buttons {
  text-align: center;
}
.signup-form {
  background: #FFF;
  border-radius: 72px; /* This value determines how rounded the corners will be */
  padding: 72px 128px 0 128px;
  margin: 24px;
  align-items: center;
  text-align: left;
  }
  
  .signup-form p {
    font-family: 'PX Grotesk Regular', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
   
  }
  
 .signup-form h3 {
    color: var(--Black, #000);
    font-family: "BaristaBot Ginto Nord";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
  }
  
  .cont-button {
    font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
    box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.10);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    background-color: #000; /* The button color from your design */
    color: var(--Neon-Yellow, #EAFF00);
    border: none;
    text-decoration: none;
    border-radius: 20px;
    padding: 16px 32px;
    margin: 24px 0 32px 0px;
    cursor: pointer;
    text-align: center;
    /* Add hover effect if needed */
  }
  
  .cont-button:hover {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    background-color: #2E2E2D;
  }
  
  .cont-button[disabled] {
    background: var(--Gray, #A5A4A3);
    color: #929292;
    cursor: not-allowed; /* Change cursor to not-allowed */
  }
  
  /* Additional styles for the disabled state if needed */
  .cont-button[disabled]:hover {
    /* Add any hover styles for the disabled state if necessary */
  }
  
  .back-button {
  font-family: 'BaristaBot Ginto Nord Bold', sans-serif;
  background-color: var(--Gray, #A5A4A3);
  color: #000000; /* Black text */
  border: 2px solid #000000; /* Grey border */
  padding: 10px 12px; /* Adjust padding to match your button's size */
  font-size: 12px; /* Adjust font-size as needed */
  border-radius: 12px; /* Adjust border-radius for roundness of corners */
  cursor: pointer; /* Changes cursor to pointer on hover */
  text-align: center; /* Ensures text is centered */
  text-decoration: none; /* Removes underline from links if used on an 'a' element */
  display: inline-block; /* Allows setting padding and other box model properties */
  margin: 24px 0 0 0; /* Spacing around the button */
  transition-duration: 0.4s; /* Smooth transition for hover effects */
  font-style: normal;
  font-weight: normal;
 
  }
  
  .back-button:hover {
    border: 3px solid #0D82FF; /* Grey border */
  }


.checkbox {
  display: flex;
  align-items: left;
  margin-bottom: 10px; /* Adjusts the space between each checkbox */
  cursor: pointer;
}

.checkbox img {
 width: 19px;
 height: 19px;
 flex-shrink: 0;
 margin-right: 6px;
}

.checkbox input[type="checkbox"] {
  margin-right: 5px; /* Smaller space between the checkbox and the label text */
}

.checkbox label {
  margin: 0; /* Removes default margin to prevent unwanted spacing */
  cursor: pointer; /* Changes the cursor to a pointer when hovering over the label */
}

.legal {
  display: flex;
  align-items: left; /* Vertically center the items */
  padding: 24px 0 24px 0px;
}

.legal {
  width: 100%;
  font-family: 'PX Grotesk Regular', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  text-align: left;

}

.legal p {
  color: var(--Black, #000);
  font-family: 'PX Grotesk Regular', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  text-align: left;
}

.legal h3 {
  color: var(--Black, #000);
  font-family: "BaristaBot Ginto Nord";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.input-label {
  color: #000;
  font-family: 'PX Grotesk Regular', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-left: 2px;

}

.pair {
  display: flex;
  justify-content: space-between;
  gap: 20px; /* Adjust the space between the two input fields */
}

.input-container {
  flex: 1; /* Each field container takes up equal space */
  display: flex;
  flex-direction: column;
  position: relative;
}

.text-input {
  display: flex;
  padding: 12px;
  border-radius: 12px;
  border: 2px solid var(--Gray, #A5A4A3); 
  background: var(--White, #FFF);
  min-width: 200px;
  font-family: 'PX Grotesk Regular', sans-serif;
  font-size: 20px;
  margin-top: 4px;
  margin-bottom: 24px;
  box-sizing: border-box;
  padding-right: 40px; /* Adjust as needed */ 
}

.text-input:hover,
.text-input.active {
  border: 3px solid var(--Blue, #0D82FF); 
  padding: 11px; 
}

.text-input.active {
  background: #E7F3FF;
  font-weight: 700;
}

.text-input::placeholder {
  color: var(--Grey, #A5A4A3);
}

.text-input.filled {
  color: var(--Black, #000);
  text-overflow: ellipsis;
}

.clear {
  display: flex;
  justify-content: center; /* Aligns buttons to the center horizontally */
  gap: 20px; /* Space between the buttons */
}


@media (max-width: 768px) { /* Adjust breakpoint as needed */

.text-input {
  margin-bottom: 24px;
  padding-right: 68px !important;
 }
 
 .signup-form {
  border-radius: 48px;
  padding: 32px 24px;
  margin: 24px;
  }
 
 .clear {
   flex-direction: column;
   grid-gap: 10px;
   
 }
 
 .form-label {
   display: block;
   margin-bottom: 8px;
   color: #000;
   font-size: 16px;
 }
 
 .form-input {
   width: 100%;
   padding: 16px;
   margin-bottom: 16px;
   border: 1px solid #ccc;
   border-radius: 8px;
 }
 
 .form-button {
   background-color: #000;
   color: #fff;
   padding: 16px;
   border: none;
   border-radius: 8px;
   width: 100%;
   cursor: pointer;
 }
 
 .form-button:hover {
   background-color: #333;
 }
 
 .password-input-container {
   position: relative;
 }
 
.password-toggle-icon {
   position: absolute;
   top: 40%;
   right: 20px;
   transform: translateY(-50%);
   cursor: pointer !important;
   width: 24px;
   height: 18px;
 }
 
 .sub-description {
   color: #000;
   font-family: 'PX Grotesk Light', sans-serif;
   font-size: 12px;
   font-style: normal;
   font-weight: 400;
   line-height: normal;
   text-transform: none;
 }
 
 .on-footer {
   background: var(--Gray, #A5A4A3);
   padding: 32px;
   align-content: center;
   
 }
 


 .on-footer-copy {
   text-align: center;
   
 }
 
 .on-footer-copy p {
    font-family: 'PX Grotesk Regular', sans-serif;
    color: #333;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.154px;
  }
 
 .on-footer-copy a {
   text-decoration: none;
   color: #333;
   cursor: pointer;
 }
 

 
 
  

 
 }
   
