.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  overflow-y: auto; /* Enables vertical scrolling if content overflows */
  z-index: 1000; /* Ensure it's above other content */
}

.modal-content {
  margin-top: 120px;
  background-color: #E6E5E1;
  width: 50%;
  height: fit-content;
  padding: 20px;
  border-radius: 24px;
  position: relative;

}

.modal-content h3 {
color: var(--Black, #000);
font-size: 32px;
}

.modal-content h2 {
color: var(--Black, #000);
font-family: 'PX Grotesk Regular', sans-serif;
font-size: 24px;

}


.modal-content p {
  color: var(--Black, #000);
  font-family: 'PX Grotesk Regular', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  text-align: left;
  
  }
  



@media (max-width: 768px) { /* Adjust breakpoint as needed */


   .modal-content h3 {
     font-size: 24px;
   }
   
   .modal-content {
     margin-top: 80px;
     width: 80%;
     height: fit-content;
     padding: 10px;
   }
   
 }
   
