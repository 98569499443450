#mc_embed_signup {
    clear: left;
    text-align: left;
    margin: 36px 24px 24px 24px;
}

.mc-field-group {
    margin-bottom: 15px;
    border: none;
}

.indicates-required {
  margin: 0 0 32px 0;
}

.checkbox {
  display: flex;
  align-items: left;
  margin-bottom: 10px; /* Adjusts the space between each checkbox */
}

.checkbox input[type="checkbox"] {
  margin-right: 5px; /* Smaller space between the checkbox and the label text */
}

.checkbox label {
  margin: 0; /* Removes default margin to prevent unwanted spacing */
  cursor: pointer; /* Changes the cursor to a pointer when hovering over the label */
}

.mailchimp-legal {
  display: flex;
  align-items: left; /* Vertically center the items */
  padding: 24px 0 24px 0px;
}

.mailchimp-logo {
  width: 65px; /* or any appropriate size */
  height: auto;
  margin-right: 10px; /* Adjust space between image and text */
}

.mailchimp-legal p {
  margin: 0; /* Removes default margin to keep layout tight */
  flex: 1; /* Takes remaining space to ensure text aligns nicely */
}


.gdpr_legal {
  width: 100%;
  font-family: 'PX Grotesk Regular', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  text-align: left;

}


.gdpr_legal p {
  color: var(--Black, #000);
  font-family: 'PX Grotesk Regular', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  text-align: left;
}

.gdpr_legal h3 {
  color: var(--Black, #000);
  font-family: "BaristaBot Ginto Nord";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.input-label {
  color: #000;
  font-family: 'PX Grotesk Regular', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-left: 2px;

}

.pair {
  display: flex;
  justify-content: space-between;
  gap: 20px; /* Adjust the space between the two input fields */
}

.field-container {
  flex: 1; /* Each field container takes up equal space */
  display: flex;
  flex-direction: column;
}

.checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
 
}

.checkbox img {
 width: 19px;
 height: 19px;
 flex-shrink: 0;
 margin-right: 6px;
}

.text-input {
  display: flex;
  padding: 12px;
  border-radius: 12px;
  border: 2px solid var(--Gray, #A5A4A3); 
  background: var(--White, #FFF);
  min-width: 200px;
  font-family: 'PX Grotesk Regular', sans-serif;
  font-size: 20px;
  margin-top: 4px;
  margin-bottom: 24px;
  box-sizing: border-box; 
}

.text-input:hover,
.text-input.active {
  border: 3px solid var(--Blue, #0D82FF); 
  padding: 11px; 
}

.text-input.active {
  background: #E7F3FF;
  font-weight: 700;
}

.text-input::placeholder {
  color: var(--Grey, #A5A4A3);
}

.text-input.filled {
  color: var(--Black, #000);
  text-overflow: ellipsis;
}

.clear {
  display: flex;
  justify-content: center; /* Aligns buttons to the center horizontally */
  gap: 20px; /* Space between the buttons */
}

.modal .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
}

.modal .submit-button, .modal .cancel-button {
  padding: 12px 24px; /* More padding */
  font-size: 12px; /* Larger font size */
  border-radius: 15px; /* More rounded corners */
  margin-bottom: 24px;
  display: inline-flex;
  box-sizing: border-box;
}



@media (max-width: 768px) { /* Adjust breakpoint as needed */

.text-input {
  margin-bottom: 8px;

 }
 
 .clear {
   flex-direction: column;
   grid-gap: 10px;
   
 }
 
 }
   
